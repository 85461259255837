import box1 from "../assets/box1.jpg";
import box2 from "../assets/box2.jpg";
import box3 from "../assets/box3.jpg";
import box4 from "../assets/box4.jpg";
export const data = [
  {
    img: box1,
    title: "Architectural Design Services",
    info: "Hassle-free and fully coordinated interior design and turnkey solutions for homes and workspaces.",
  },
  {
    img: box2,
    title: "Interior Designing Services",
    info: "We work with top emerging residential & commercial interior designers and have a highly rated & qualified in-house design team.",
  },
  {
    img: box3,
    title: "Town Planning",
    info: "Pick from our highly curated range of modular furniture or let our designers help you customize and fine-tune to suit your style.",
  },
  {
    img: box4,
    title: "Vastu Consultation",
    info: "We also offer 'design-only' solutions, work with your contractors & materials and oversee execution, for suitable projects.",
  },
];

export const ourWork=[
  {
    id:'1',
    tile_image:'https://amazingarchitecture.com/storage/1827/responsive-images/black_house_amin_moazzen_bangal_india___media_library_original_1344_756.jpg',
    tile_text:'Maple Manor',
    main_image:'',
    location:'Nagpur',
    date:{
      date:'',
      month:'',
      year:'2024'
    },
    area:'',
    desc1:'',
    images:[]
  },
  {
    id:'1',
    tile_image:'https://i.pinimg.com/736x/d8/a8/34/d8a83483db6cfdc5f6aeed6fe5c60f2d.jpg',
    tile_text:'Maple Manor',
    main_image:'',
    location:'Nagpur',
    date:{
      date:'',
      month:'',
      year:'2024'
    },
    area:'',
    desc1:'',
    images:[]
  },
  {
    id:'1',
    tile_image:'https://i.pinimg.com/736x/51/09/ff/5109fffa2832fdce03a853425194d99c.jpg',
    tile_text:'Maple Manor',
    main_image:'',
    location:'Nagpur',
    date:{
      date:'',
      month:'',
      year:'2024'
    },
    area:'',
    desc1:'',
    images:[]
  },
  {
    id:'1',
    tile_image:'https://i.pinimg.com/736x/04/14/32/0414329f76f71069c56a5b89b4e0da58.jpg',
    tile_text:'Maple Manor',
    main_image:'',
    location:'Nagpur',
    date:{
      date:'',
      month:'',
      year:'2024'
    },
    area:'',
    desc1:'',
    images:[]
  },
  {
    id:'1',
    tile_image:'https://i.pinimg.com/736x/18/8e/ba/188eba387b542b82f58d189947f6619a.jpg',
    tile_text:'Maple Manor',
    main_image:'',
    location:'Nagpur',
    date:{
      date:'',
      month:'',
      year:'2024'
    },
    area:'',
    desc1:'',
    images:[]
  },
  {
    id:'1',
    tile_image:'https://i.pinimg.com/736x/07/ac/ac/07acac4695b1b9f952a4075636c12029.jpg',
    tile_text:'Maple Manor',
    main_image:'',
    location:'Nagpur',
    date:{
      date:'',
      month:'',
      year:'2024'
    },
    area:'',
    desc1:'',
    images:[]
  },
  {
    id:'1',
    tile_image:'https://amazingarchitecture.com/storage/1827/responsive-images/black_house_amin_moazzen_bangal_india___media_library_original_1344_756.jpg',
    tile_text:'Maple Manor',
    main_image:'',
    location:'Nagpur',
    date:{
      date:'',
      month:'',
      year:'2024'
    },
    area:'',
    desc1:'',
    images:[]
  },
  {
    id:'1',
    tile_image:'https://i.pinimg.com/736x/79/99/7e/79997e9138cbbc76856192aee28cc47c.jpg',
    tile_text:'Maple Manor',
    main_image:'',
    location:'Nagpur',
    date:{
      date:'',
      month:'',
      year:'2024'
    },
    area:'',
    desc1:'',
    images:[]
  },
  {
    id:'1',
    tile_image:'https://i.pinimg.com/736x/3a/60/49/3a60496e7e3c51bf4ed0cffc0ef09bbd.jpg',
    tile_text:'Maple Manor',
    main_image:'',
    location:'Nagpur',
    date:{
      date:'',
      month:'',
      year:'2024'
    },
    area:'',
    desc1:'',
    images:[]
  },

  
]

export const ourWork2=[
  {
    id:'1',
    tile_image:'https://i.pinimg.com/736x/8d/bf/a4/8dbfa48e6eec926ca0f3d55253699031.jpg',
    tile_text:'Maple Manor',
    main_image:'',
    location:'Nagpur',
    date:{
      date:'',
      month:'',
      year:'2024'
    },
    area:'',
    desc1:`lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since,`,
    images:[]
  },
  {
    id:'1',
    tile_image:'https://i.pinimg.com/736x/96/59/bc/9659bc3c1f7e6d191e586d4ce79b4245.jpg',
    tile_text:'Maple Manor',
    main_image:'',
    location:'Nagpur',
    date:{
      date:'',
      month:'',
      year:'2024'
    },
    area:'',
    desc1:`lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since,`,
    images:[]
  },
  {
    id:'1',
    tile_image:'https://i.pinimg.com/736x/fc/38/aa/fc38aa2f7e1194c652c3d0ecc295169a.jpg',
    tile_text:'Maple Manor',
    main_image:'',
    location:'Nagpur',
    date:{
      date:'',
      month:'',
      year:'2024'
    },
    area:'',
    desc1:`lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since,`,
    images:[]
  },
  {
    id:'1',
    tile_image:'https://i.pinimg.com/736x/a0/8e/8c/a08e8c053659d8d7060b99fb37b5f44e.jpg',
    tile_text:'Maple Manor',
    main_image:'',
    location:'Nagpur',
    date:{
      date:'',
      month:'',
      year:'2024'
    },
    area:'',
    desc1:`lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since,`,
    images:[]
  },
  {
    id:'1',
    tile_image:'https://i.pinimg.com/736x/ba/99/2b/ba992b2d23e610848df7cb514c31d9a4.jpg',
    tile_text:'Maple Manor',
    main_image:'',
    location:'Nagpur',
    date:{
      date:'',
      month:'',
      year:'2024'
    },
    area:'',
    desc1:`lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since,`,
    images:[]
  },
  {
    id:'1',
    tile_image:'https://i.pinimg.com/736x/5f/b1/61/5fb161c98f69034e2064fa9ee5eddb15.jpg',
    tile_text:'Maple Manor',
    main_image:'',
    location:'Nagpur',
    date:{
      date:'',
      month:'',
      year:'2024'
    },
    area:'',
    desc1:`lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since,`,
    images:[]
  },
  

  
]

export const ourTeam=[
  {
    id:'1',
    image:'https://img.freepik.com/free-photo/business-finance-employment-female-successful-entrepreneurs-concept-confident-smiling-asian-businesswoman-office-worker-white-suit-glasses-using-laptop-help-clients_1258-59126.jpg',
    name:'John Doe',
    role:'Project Manager',
    desc:`lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`
  },
  {
    id:'1',
    image:'https://img.freepik.com/free-photo/business-finance-employment-female-successful-entrepreneurs-concept-confident-smiling-asian-businesswoman-office-worker-white-suit-glasses-using-laptop-help-clients_1258-59126.jpg',
    name:'John Doe',
    role:'Project Manager',
    desc:`lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`
  },
  {
    id:'1',
    image:'https://img.freepik.com/free-photo/business-finance-employment-female-successful-entrepreneurs-concept-confident-smiling-asian-businesswoman-office-worker-white-suit-glasses-using-laptop-help-clients_1258-59126.jpg',
    name:'John Doe',
    role:'Project Manager',
    desc:`lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`
  },
  {
    id:'1',
    image:'https://img.freepik.com/free-photo/business-finance-employment-female-successful-entrepreneurs-concept-confident-smiling-asian-businesswoman-office-worker-white-suit-glasses-using-laptop-help-clients_1258-59126.jpg',
    name:'John Doe',
    role:'Project Manager',
    desc:`lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`
  },

]
